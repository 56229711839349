import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams, useLocation } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom'
import Csr from '../../api/csr'
// import ServiceTab from './ServiceTab';
import Logo from '../../images/ffsdgroup.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Research from '../../components/Research/Research';
import Charity from '../../components/charity/charity';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
    e.preventDefault()
}

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


const CsrSinglePage = (props) => {
    
    const location = useLocation();

    const { slug } = useParams()

    const CsrDetails = Csr.find(item => item.slug === slug)

    

    return (
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={CsrDetails.sTitle} pagesub={CsrDetails.sTitle} />

            <section className="service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 order-lg-2 order-1 col-12">
                            <div className="service-single-content">
                                <div className="service-pic">
                                {location.pathname === "/csr/Research-Grant" ?   <Research /> : <Charity />}
                                    {/* <img src={CsrDetails.sImg} alt="" /> */}
                                </div>
                                <h2>{CsrDetails.sTitle}</h2>
                                <p>{CsrDetails.description}</p>
                                <p>{CsrDetails.des2}</p>
                                <p>{CsrDetails.des3}</p>
                                <p>{CsrDetails.des4}</p>
                                <p>{CsrDetails.des5}</p>
                                <p>{CsrDetails.des6}</p>
                                <p>{CsrDetails.des7}</p>
                                <p>{CsrDetails.des8}</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 order-lg-1 order-2 col-12">
                            <div className="service-sidebar">
                                <div className="widget service-list-widget">
                                    <ul>
                                        <li className="current"><Link to="/services">All Service</Link></li>
                                        {Csr.map((csr, srv) => (
                                            <li className='current' key={srv}><Link onClick={ClickHandler} to={`/csr/${csr.slug}`}>{csr.sTitle}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="widget download-widget">
                                    <ul>
                                        <li>
                                        <a href='' target="" rel="noopener noreferrer"><i className="ti-file"></i>Visit Website</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget contact-widget">
                                    <div>
                                        <h4>Need help?</h4>
                                        <p>We would be glad to profer Tailored solutions to you. Available 24/7 to attend to your needs</p>
                                        <p>Phone: 1234567890</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CsrSinglePage;
