import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";

const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [profileActive, setProfileState] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const { carts } = props;

    return (
        <header id="header" className={`site-header ${props.hclass}`}>
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/">
                            <img src={props.Logo} alt="" />
                        </Link>
                    </div>
                    <div
                        id="navbar"
                        className="collapse navbar-collapse navigation-holder"
                    >
                        <button className="close-navbar">
                            <i className="ti-close"></i>
                        </button>

                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                            <li className="">
                                <Link onClick={ClickHandler} to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/about">
                                    About
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/about">Background</Link></li>
                                    <li><Link onClick={ClickHandler} to="">Career</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/services">Services</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to={`/service/Study-Abroad`}>Study Abroad</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/service/DocumentVerification`}>Document Verification</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/service/travels`}>Travels and Tour</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/service/Workstation`}>Workstation</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/events">Events</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to={`/events/10th-Anniversary`}>10th Anniversary</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/events/Pre-departure-Events`}>Pre-Departure Event</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/events/Business-Retreat`}>Business Retreat</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/events/Education-Fair`}>Education Fair</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/csr">CSR</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to={`/csr/Research-Grant`}>Research Grant</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/csr/Charity`}>Charity</Link></li>
                                    {/* <li><Link onClick={ClickHandler} to={`/csr/Business-Retreat`}>Business Retreat</Link></li>
                                    <li><Link onClick={ClickHandler} to={`/csr/Education-Fair`}>Education Fair</Link></li> */}
                                </ul>
                            </li>
                            <li className="">
                                <Link onClick={ClickHandler} to="">
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link onClick={ClickHandler} to="/contact">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="cart-search-contact">
                        {/* <div className="header-search-form-wrapper">
                            <button
                                onClick={() => setMenuState(!menuActive)}
                                className="search-toggle-btn"
                            >
                                <i
                                    className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}
                                ></i>
                            </button>
                            <div
                                className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""
                                    }`}
                            >
                                <form onSubmit={SubmitHandler}>
                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here..."
                                        />
                                        <button type="submit">
                                            <i className="ti-search"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div> */}

                        <div className="header-search-form-wrapper">
                            <button
                                onClick={() => {}}
                                // onClick={toggleModal}
                                className="search-toggle-btn"
                            >
                                <i
                                    className={`fi ti-user`}
                                ></i>
                            </button>

                            {isModalOpen && (
                                <div className="modal-overlay">
                                    <div className="modal-content">
                                        <button onClick={toggleModal} className="close-modal-btn">
                                            Close
                                        </button>
                                        <h2>Login Form</h2>
                                        <form>
                                            <div>
                                                <label htmlFor="username">Username</label>
                                                <input type="text" id="username" placeholder="Enter your username" />
                                            </div>
                                            <div>
                                                <label htmlFor="password">Password</label>
                                                <input type="password" id="password" placeholder="Enter your password" />
                                            </div>
                                            <button type="submit">Login</button>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
